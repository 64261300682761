


































































import { Component, Mixins } from 'vue-property-decorator'
import UtilsMixin from '@/mixins/UtilsMixin'

@Component({
  name: 'delegate',
})
export default class DelegateComponent extends Mixins<UtilsMixin>(UtilsMixin) {


}
