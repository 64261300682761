
































import { Component, Mixins } from 'vue-property-decorator'
import UtilsMixin from '@/mixins/UtilsMixin'

@Component({
  name: 'earn',
})
export default class EarnComponent extends Mixins<UtilsMixin>(UtilsMixin) {

}
